import useSWR from "swr";
import api from "../data/api";
import { noop } from "../utils";
import { useCallback } from "react";

export default function useAPI(
	url,
	{ handler = noop, initialValue = [] } = {}
) {
	const fetcher = useCallback(
		(key) => {
			return api.get(key).then((json) => handler(json));
		},
		[handler]
	);

	const { data, error, isLoading } = useSWR(url, fetcher, {
		fallbackData: initialValue,
	});

	return { loading: isLoading, value: data, error };
}
