import { useParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import Field from "../FormTemplate/Field";
import Select from "../FormTemplate/Select";
import FormCreate from "../FormTemplate/FormCreate";
import FormUpdate from "../FormTemplate/FormUpdate";
import { Button, AbortButton } from "../Template/Button";
import { FormLoader } from "../Loader";
import { goToLogs, PATH_CYCLES } from "../../endpointConfig";

const CycleFields = ({ cycle = {}, title = "Lägg till: menstruation" }) => (
	<fieldset className="form">
		<legend>{title}</legend>
		<div className="form__layout">
			<section className="form__cell-grid">
				<h2>Datum</h2>
				<Field
					type="date"
					id="menstrual-start"
					name="startdate"
					label="Start"
					value={cycle.startdate}
					required
				/>
				<Field
					type="date"
					id="menstrual-end"
					name="enddate"
					label="Slut"
					value={cycle.enddate}
				/>
			</section>
			<Select
				id="menstrual-flow"
				name="flow"
				title="Flöde"
				standard="Välj flöde"
				value={cycle.flow}
			>
				<option value="light">Lätt</option>
				<option value="normal">Normal</option>
				<option value="super">Rikligt</option>
			</Select>
			<Select
				id="menstrual-pain"
				name="pain"
				title="Smärtnivå"
				standard="Välj nivå"
				value={cycle.pain}
			>
				<option value="none">Ingen smärta</option>
				<option value="mild">Lätt smärta</option>
				<option value="moderate">Måttlig smärta</option>
				<option value="severe">Svår smärta</option>
				<option value="unbearable">Outhärdlig smärta</option>
				<option value="worst">Värsta tänkbara smärta</option>
			</Select>
			<Field
				as="textarea"
				id="menstrual-note"
				name="note"
				label="Valfri anteckning"
				value={cycle.note}
				rows="8"
			/>
		</div>
		<div className="btn-container">
			<AbortButton
				pathTo={`.${goToLogs}/${PATH_CYCLES}`}
				label="Avbryt"
			/>
			<Button label="Klar" modifier="default" />
		</div>
	</fieldset>
);

export const AddCycle = () => {
	return (
		<FormCreate endpoint={PATH_CYCLES} goTo={`${goToLogs}/${PATH_CYCLES}`}>
			<CycleFields />
		</FormCreate>
	);
};

export const EditCycle = () => {
	const { id } = useParams();
	const endpoint = `${PATH_CYCLES}/${id}`;
	const { value: cycle, loading } = useAPI(endpoint);

	if (loading) {
		return <FormLoader />;
	}

	return (
		<FormUpdate
			endpoint={endpoint}
			goTo={`${goToLogs}/${PATH_CYCLES}`}
			values={cycle}
		>
			<CycleFields title="Redigera menstruation" cycle={cycle} />
		</FormUpdate>
	);
};
