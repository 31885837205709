import { useMemo } from "react";
import { timeBetween } from "../utils";

export default function useGroupRelativeTime(groups) {
	const relativeTime = useMemo(() => {
		const [firstGroup] = groups;
		const items = firstGroup ? firstGroup[1] : [];
		const item = items ? items[0] : null;
		const timeAgo = item ? timeBetween(item.date, Date.now()) : "";

		return timeAgo;
	}, [groups]);

	return relativeTime;
}
