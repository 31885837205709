import useAPI from "../../../hooks/useAPI";
import StartpageBubble from "../StartpageBubble";
import DisplayNoDataMessage from "../../Template/DisplayNoDataMessage";

const Meds = () => {
	const { value: med, loading } = useAPI("medicamento-logg/today");

	return (
		<StartpageBubble title="Medicinering" loading={loading}>
			{med != null ? (
				<ol>
					{med.map((x) => (
						<li key={x.id}>{`${x.name} ${
							x.quantity >= 1 ? `(${x.quantity})` : ""
						} `}</li>
					))}
				</ol>
			) : (
				<DisplayNoDataMessage classes="startpage__bubble-msg" />
			)}
		</StartpageBubble>
	);
};

export default Meds;
