import { getToken } from "./token";

const apiUrl = process.env.REACT_APP_API_URL;

function handleErrors(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}

	return response;
}

function setHeaders() {
	const token = getToken();
	const headers = { "Content-Type": "application/json" };

	if (token) {
		headers.Authorization = "Bearer " + token;
	}

	return headers;
}

const api = {
	get(endpoint) {
		const url = apiUrl + endpoint;
		return fetch(url, {
			headers: setHeaders(),
		})
			.then(handleErrors)
			.then((response) => response.json());
	},
	post(endpoint, data) {
		const url = apiUrl + endpoint;

		return fetch(url, {
			method: "POST",
			body: JSON.stringify(data),
			headers: setHeaders(),
		})
			.then(handleErrors)
			.then((response) => response.json());
	},
	put(endpoint, data) {
		const url = apiUrl + endpoint;

		return fetch(url, {
			method: "PUT",
			body: JSON.stringify(data),
			headers: setHeaders(),
		})
			.then(handleErrors)
			.then((response) => response.json());
	},
	delete(endpoint) {
		const url = apiUrl + endpoint;

		return fetch(url, {
			method: "DELETE",
			headers: setHeaders(),
		})
			.then(handleErrors)
			.then((response) => response.json());
	},
};

export default api;
