import { formatDate } from "../../utils";
import Field from "../FormTemplate/Field";
import RadioOption from "../FormTemplate/Radio";
import FormCreate from "../FormTemplate/FormCreate";
import { Button } from "../Template/Button";

const table = "survey/gad7";

const Answers = ({ number }) => (
	<>
		<RadioOption
			number={number}
			cat="q"
			option="none"
			label="Inte alls"
			value="0"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="slightly"
			label="Lite grann"
			value="1"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="moderate"
			label="Måttligt"
			value="2"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="much"
			label="Ganska mycket"
			value="3"
		/>
	</>
);

const Section = ({ q, num }) => (
	<section className="field field--survey">
		<h3>{q}</h3>
		<Answers number={num} />
	</section>
);

const GAD7Fields = ({
	gad7 = {
		date: formatDate(new Date()),
	},
	title = "GAD-7",
}) => (
	<fieldset className="form form--survey">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				id="suvey-date"
				name="date"
				type="date"
				label="Datum"
				value={gad7.date}
			/>
			<section className="form__note">
				<h3>Anteckning</h3>
				<p>
					Svara för de senaste 14 dagarna.
					<br />
					Originella svarsalternativen, samma poängsättning 0-3:
				</p>
				<ul>
					<li>Inte alls</li>
					<li>Flera dagar</li>
					<li>Mer än hälften av dagarna</li>
					<li>Nästan varje dag</li>
				</ul>
			</section>
			<p className="form__instructions">
				Fyll i enkäten utifrån hur det har varit sedan föregående gång.
				Hur ofta har du besvärats av följande problem?
			</p>
			<Section
				q="Känt dig nervös, ängslig eller väldigt stressad"
				num="1"
			/>
			<Section
				q="Inte kunnat sluta oroa dig eller kontrollera din oro"
				num="2"
			/>
			<Section q="Oroat dig för mycket för olika saker" num="3" />
			<Section q="Haft svårt att slappna av" num="4" />
			<Section
				q="Varit så rastlös att du har haft svårt att sitta still"
				num="5"
			/>
			<Section q="Blivit lätt irriterad eller retlig" num="6" />
			<Section
				q="Känt dig rädd för att något hemskt skulle hända"
				num="7"
			/>
			<Section
				q="Om du svarat att du haft något av dessa problem, hur svårt har dessa problem gjort det för dig att utföra ditt arbete, ta hand om saker hemma, eller att komma överens med andra människor?"
				num="8"
			/>
		</div>
		<div className="btn-container">
			<Button label="Spara" modifier="default" />
		</div>
	</fieldset>
);

export const AddGAD7 = () => {
	return (
		<FormCreate endpoint={table} goTo="/surveys">
			<GAD7Fields />
		</FormCreate>
	);
};
