import { useMemo } from "react";
import useAPI from "../../hooks/useAPI";
import { timeBetween } from "../../utils";
import Delete from "../FormTemplate/Delete";
import { EditButton } from "../Template/Button";
import Accordion from "../Accordion";
import Loader from "../Loader";

const Sickness = () => {
	const { value: humanState, loading } = useAPI("morbus");

	const latest = useMemo(() => {
		return humanState[0] || {};
	}, [humanState]);

	return (
		<section className="section">
			<h1 className="section__title">Katalog [sjukdom]</h1>
			<p className="section__stats">
				{latest.enddate ? (
					<>
						<span>
							Du har varit frisk{" "}
							{timeBetween(Date.now(), latest.enddate)}.
						</span>
						<span>
							Sjukdom pågick{" "}
							{timeBetween(latest.enddate, latest.startdate)}.
						</span>
					</>
				) : (
					<span>
						Du har varit sjuk i{" "}
						{timeBetween(latest.startdate, Date.now())}.
					</span>
				)}
			</p>
			{loading ? (
				<Loader />
			) : (
				<>
					{humanState.map(
						({ id, startdate, enddate, name, symptom, note }) => (
							<Accordion key={id} name={name} modifier="list">
								<dl>
									<div>
										<dt>Tillstånd</dt>
										<dd>{name}</dd>
									</div>

									<div>
										<dt>Start</dt>
										<dd>
											<time dateTime={startdate}>
												{startdate}
											</time>
										</dd>
									</div>

									{enddate ? (
										<div>
											<dt>Slut</dt>
											<dd>
												<time dateTime={enddate}>
													{" "}
													{enddate}
												</time>
											</dd>
										</div>
									) : (
										""
									)}

									{symptom ? (
										<div>
											<dt>Symptom</dt>
											<dd className="u-dd-note">
												{symptom}
											</dd>
										</div>
									) : (
										""
									)}

									{note ? (
										<div className="u-cell-span">
											<dt>Anteckning</dt>
											<dd className="u-dd-note">
												{note}
											</dd>
										</div>
									) : (
										""
									)}
								</dl>
								<div className="btn-container">
									<Delete
										id={id}
										label={name}
										type="morbus"
									/>
									<EditButton id={id} segment="morbus" />
								</div>
							</Accordion>
						)
					)}
				</>
			)}
		</section>
	);
};

export default Sickness;
