import { useParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import Field from "../FormTemplate/Field";
import Checkbox from "../FormTemplate/Checkbox";
import FormCreate from "../FormTemplate/FormCreate";
import FormUpdate from "../FormTemplate/FormUpdate";
import { Button, AbortButton } from "../Template/Button";
import { FormLoader } from "../Loader";
import { goToLogs, PATH_DRUG } from "../../endpointConfig";

const DrugFields = ({ drug = {}, title = "Lägg till: Medicin" }) => (
	<fieldset className="form">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				id="medicine-name"
				name="name"
				label="Namn på medicin"
				value={drug.name}
				required
			/>
			<section className="form__cell-grid">
				<h2>Datum</h2>
				<Field
					type="date"
					id="medicine-start"
					name="startdate"
					label="Start"
					value={drug.startdate}
					required
				/>
				<Field
					type="date"
					id="medicine-end"
					name="enddate"
					label="Slut"
					value={drug.enddate}
				/>
			</section>
			<section className="form__cell-grid">
				<h2>Detaljer</h2>
				<Field
					id="medicine-form"
					name="form"
					label="Beredningsform"
					value={drug.form}
					placeholder="T.ex. tabletter"
				/>
				<Field
					id="medicine-daily"
					name="daily_dosage"
					label="Daglig dos"
					value={drug.daily_dosage}
					placeholder="T.ex. 1"
				/>
				<Field
					type="number"
					id="medicine-strength"
					name="strength"
					label="Styrka"
					value={drug.strength}
					placeholder="T.ex. 20"
				/>
				<Field
					id="medicine-unit"
					name="unit"
					label="Enhet"
					value={drug.unit}
					placeholder="T.ex. mg"
				/>
			</section>
			<Field
				id="medicine-dosage"
				name="dosage"
				label="Dosering"
				value={drug.dosage}
				placeholder="T.ex. 1 tablett/dag på morgonen"
			/>
			<Field
				as="textarea"
				id="medicine-note"
				name="note"
				label="Valfri anteckning"
				value={drug.note}
				rows="8"
			/>
			<div className="form__layout-check">
				<Checkbox
					id="medicine-active"
					name="active"
					label="Aktiv medicin"
					value={drug.active}
				/>
				<Checkbox
					id="medicine-fav"
					name="fav"
					label="Daglig medicinsk rutin"
					value={drug.fav}
				/>
			</div>
		</div>
		<div className="btn-container">
			<AbortButton pathTo={`.${goToLogs}/${PATH_DRUG}`} label="Avbryt" />
			<Button label="Klar" modifier="default" />
		</div>
	</fieldset>
);

export const AddDrug = () => {
	return (
		<FormCreate endpoint={PATH_DRUG} goTo={`${goToLogs}/${PATH_DRUG}`}>
			<DrugFields />
		</FormCreate>
	);
};

export const EditDrug = () => {
	const { id } = useParams();
	const endpoint = `${PATH_DRUG}/${id}`;
	const { value: medicamento, loading } = useAPI(endpoint);

	if (loading) {
		return <FormLoader />;
	}

	return (
		<FormUpdate
			endpoint={endpoint}
			goTo={`${goToLogs}/${PATH_DRUG}`}
			values={medicamento}
		>
			<DrugFields
				title={`Redigera ${medicamento.name}`}
				drug={medicamento}
			/>
		</FormUpdate>
	);
};
