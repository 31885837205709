import { moodLabel, moodMap } from "../../../utils";
import useAPI from "../../../hooks/useAPI";
import StartpageBubble from "../StartpageBubble";
import DisplayNoDataMessage from "../../Template/DisplayNoDataMessage";

const Mood = () => {
	const { value: motus, loading } = useAPI("motus/today");

	return (
		<StartpageBubble title="Humör" loading={loading}>
			{motus != null ? (
				<div className="startpage__inner-mood">
					<div className="mood__icon">{moodMap(motus.mood)}</div>
					<span className="mood__label">{moodLabel(motus.mood)}</span>
				</div>
			) : (
				<DisplayNoDataMessage classes="startpage__bubble-msg" />
			)}
		</StartpageBubble>
	);
};

export default Mood;
