import { Link } from "react-router-dom";

const ButtonDecore = () => (
	<div className="btn__icon-decore">
		<div className="btn__icon-decore__line"></div>
		<div className="btn__icon-decore__arrow"></div>
	</div>
);

export const Button = ({ label, modifier, ...rest }) => (
	<button className={`btn ${modifier ? `btn--${modifier}` : ""}`} {...rest}>
		{label}
		<ButtonDecore />
	</button>
);

export const DeleteButton = ({ label, modifier, ...rest }) => (
	<button className={`btn ${modifier ? `btn--${modifier}` : ""}`} {...rest}>
		{label}
	</button>
);

export const AbortButton = ({ pathTo, label }) => (
	<Link to={`/${pathTo}`} className="btn btn--cancel">
		{label}
	</Link>
);

export const EditButton = ({ id, segment }) => (
	<Link to={`/${segment}/${id}/edit`} className="btn btn--default">
		Ändra
		<ButtonDecore />
	</Link>
);

export const EditButtonXS = ({ id, segment }) => (
	<Link to={`/${segment}/${id}/edit`} className="btn btn--edit-small">
		+
	</Link>
);
