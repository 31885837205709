export default function CalendarHeader({ cta, title, children }) {
	return (
		<header className="calendar-presentation__header">
			<h2 className="calendar-presentation__title">
				{title !== "" ? title : children}
			</h2>
			<button
				className="calendar-presentation__close-btn"
				aira-label="Stäng bubbla"
				onClick={cta}
			>
				<span className="visually-hidden">Stäng</span>
				<span className="calendar-presentation__close-btn-icon"></span>
			</button>
		</header>
	);
}
