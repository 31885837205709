import { useMemo } from "react";
import useAPI from "../../hooks/useAPI";
import { shortDateFormat, moodLabel, moodMap, formatDate } from "../../utils";
import Loader from "../Loader";
import useCalendar from "../Calendar/useCalendar";
import CalendarNav from "../Calendar/CalendarNav";
import DisplayNoDataMessage from "../Template/DisplayNoDataMessage";

const MoodCalendar = () => {
	const { value: mood, loading } = useAPI("motus");
	const dates = mood.map((x) => new Date(x.date));
	const calendar = useCalendar(dates);
	const date = calendar.date;

	const filterMoods = useMemo(() => {
		const yymm = formatDate(date).substr(0, 7);

		return mood.filter((x) => x.date.substr(0, 7) === yymm);
	}, [date, mood]);

	return (
		<section className="section section--mood">
			<h1 className="section__title">Året i emoticon</h1>
			<div className="calendar">
				<header className="calendar__nav">
					<CalendarNav calendar={calendar} />
				</header>
				{loading ? (
					<Loader />
				) : filterMoods.length > 0 ? (
					<ul className="datalist moodlist">
						{filterMoods.map(({ id, date, mood }) => (
							<li
								key={id}
								className="datalist__item moodlist__item"
							>
								<span className="mood__date">
									{shortDateFormat(date)}
								</span>
								<div className="mood__icon">
									{moodMap(mood)}
								</div>
								<span className="mood__label">
									{moodLabel(mood)}
								</span>
							</li>
						))}
					</ul>
				) : (
					<DisplayNoDataMessage classes="calendar-presentation__msg" />
				)}
			</div>
		</section>
	);
};

export default MoodCalendar;
