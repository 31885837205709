const Checkbox = ({ id, name, label, value, ...rest }) => (
	<div className="checkbox">
		<input type="hidden" name={name} value="0" />
		<input
			{...rest}
			id={id}
			name={name}
			type="checkbox"
			value="1"
			defaultChecked={value !== undefined && value !== "0" ? true : null} // Det finns ett värde och det är inte likamed det andra (standard)
			className="checkbox__input"
		/>
		<label htmlFor={id} className="toggle">
			<span className="toggle-indicator"></span>
			<span aria-label={label}>{label}</span>
		</label>
	</div>
);

export default Checkbox;
