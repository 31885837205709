const Select = ({ id, name, value, title, standard, children, ...rest }) => {
	const classes = ["field", "field--select"].filter(Boolean).join(" ");

	return (
		<div className={classes}>
			<label htmlFor={id} className="field__label">
				{title}
			</label>
			<select
				id={id}
				name={name}
				defaultValue={value}
				className="field__input"
				{...rest}
			>
				<option value="">{standard}</option>
				{children}
			</select>
		</div>
	);
};

export default Select;
