import useAPI from "../../../hooks/useAPI";
import StartpageBubble from "../StartpageBubble";
import DisplayNoDataMessage from "../../Template/DisplayNoDataMessage";

const Events = () => {
	const { value: data, loading } = useAPI("positivismum/today", {
		initialValue: {},
	});

	return (
		<StartpageBubble title="Positiva stunder" loading={loading}>
			{data.id != null ? (
				data.event ? (
					<ol>
						{data.event
							.split(/\r|\n/)
							.filter(Boolean)
							.map((item) => (
								<li key={item}>{item}</li>
							))}
					</ol>
				) : (
					<div className="startpage__bubble-msg">Dålig dag</div>
				)
			) : (
				<DisplayNoDataMessage classes="startpage__bubble-msg" />
			)}
		</StartpageBubble>
	);
};

export default Events;
