import { formatDate } from "../../utils";
import Field from "../FormTemplate/Field";
import RadioOption from "../FormTemplate/Radio";
import FormCreate from "../FormTemplate/FormCreate";
import { Button } from "../Template/Button";

const table = "survey/phq9";

const Answers = ({ number }) => (
	<>
		<RadioOption
			number={number}
			cat="q"
			option="none"
			label="Inte alls"
			value="0"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="slightly"
			label="Lite grann"
			value="1"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="moderate"
			label="Måttligt"
			value="2"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="much"
			label="Ganska mycket"
			value="3"
		/>
	</>
);

const Section = ({ q, num }) => (
	<section className="field field--survey">
		<h3>{q}</h3>
		<Answers number={num} />
	</section>
);

const PHQ9Fields = ({
	phq9 = {
		date: formatDate(new Date()),
	},
	title = "PHQ-9",
}) => (
	<fieldset className="form form--survey">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				id="suvey-date"
				name="date"
				type="date"
				label="Datum"
				value={phq9.date}
			/>
			<section className="form__note">
				<h3>Anteckning</h3>
				<p>
					Svara för de senaste 14 dagarna.
					<br />
					Originella svarsalternativen, samma poängsättning 0-3:
				</p>
				<ul>
					<li>Inte alls</li>
					<li>Flera dagar</li>
					<li>Mer än hälften av dagarna</li>
					<li>Nästan varje dag</li>
				</ul>
			</section>
			<p className="form__instructions">
				Fyll i enkäten utifrån hur det har varit sedan föregående gång.
				Hur mycket har du besvärats av något av följande problem?
			</p>
			<Section q="Lite intresse eller glädje av att göra saker" num="1" />
			<Section
				q="Känt dig nedstämd, deprimerad eller upplevt känsla av hopplöshet"
				num="2"
			/>
			<Section
				q="Svårigheter att somna eller få en sammanhängande sömn, eller sovit för mycket"
				num="3"
			/>
			<Section q="Känt dig trött eller haft för lite energi" num="4" />
			<Section q="Dålig aptit eller ätit för mycket" num="5" />
			<Section
				q="Tycker illa om dig själv &ndash; eller att du känt dig misslyckad eller att du svikit dig själv eller din familj"
				num="6"
			/>
			<Section
				q="Svårigheter att koncentrera dig på saker, till exempel att läsa tidningen eller att titta på TV"
				num="7"
			/>
			<Section
				q="Att du rört dig eller talat så långsamt att andra människor märkt det? Eller motsatsen &ndash; att du varit så nervös eller rastlös att du rört dig mer än vanligt"
				num="8"
			/>
			<Section
				q="Tankar att det skulle vara bättre om du var död eller att du skulle skada dig på något sätt"
				num="9"
			/>
			<Section
				q="Om du svarat att du haft något av dessa problem, hur svårt har dessa problem gjort det för dig att utföra ditt arbete, ta hand om saker hemma, eller att komma överens med andra människor?"
				num="10"
			/>
		</div>
		<div className="btn-container">
			<Button label="Spara" modifier="default" />
		</div>
	</fieldset>
);

export const AddPHQ9 = () => {
	return (
		<FormCreate endpoint={table} goTo="/surveys">
			<PHQ9Fields />
		</FormCreate>
	);
};
