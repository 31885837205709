const Field = ({
	as: Element = "input",
	id,
	name,
	type = "text",
	label,
	value,
	placeholder = "Skriv här...",
	...rest
}) => {
	const classes = [
		"field",
		Element === "textarea" ? "field--textarea" : `field--${type}`,
	]
		.filter(Boolean)
		.join(" ");

	return (
		<div className={classes}>
			<label htmlFor={id} className="field__label">
				{label}
			</label>
			<Element
				{...rest}
				id={id}
				name={name}
				type={type}
				defaultValue={value}
				className="field__input"
				placeholder={placeholder}
			/>
		</div>
	);
};

export default Field;
