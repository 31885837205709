import { useParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import FormCreate from "../FormTemplate/FormCreate";
import FormUpdate from "../FormTemplate/FormUpdate";
import Field from "../FormTemplate/Field";
import { Button, AbortButton } from "../Template/Button";
import { FormLoader } from "../Loader";
import { swedishDateFormat, formatDate } from "../../utils";
import { goToLogs, PATH_HURT } from "../../endpointConfig";

const HurtFields = ({
	sad = {
		date: formatDate(new Date()),
	},
	title = "Nya skador",
}) => (
	<fieldset className="form">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				type="date"
				id="sad-date"
				name="date"
				label="Datum"
				value={sad.date}
				required
			/>
			<Field
				type="number"
				id="sad-total"
				name="total"
				label="Totalt"
				value={sad.total}
				placeholder="Ange en siffra.."
				required
			/>
		</div>
		<div className="btn-container">
			<AbortButton pathTo={`.${goToLogs}/${PATH_HURT}`} label="Avbryt" />
			<Button label="Klar" modifier="default" />
		</div>
	</fieldset>
);

export const AddHurt = () => {
	return (
		<FormCreate endpoint={PATH_HURT} goTo={`${goToLogs}/${PATH_HURT}`}>
			<HurtFields />
		</FormCreate>
	);
};

export const EditHurt = () => {
	const { id } = useParams();
	const endpoint = `${PATH_HURT}/${id}`;
	const { value: tristis, loading } = useAPI(endpoint);

	if (loading) {
		return <FormLoader />;
	}

	return (
		<FormUpdate
			endpoint={endpoint}
			goTo={`${goToLogs}/${PATH_HURT}`}
			values={tristis}
		>
			<HurtFields
				title={`Redigera ${swedishDateFormat(tristis.date)}`}
				sad={tristis}
			/>
		</FormUpdate>
	);
};
