import { isDateEqual } from "../../utils";

export default function CalendarCell({
	date,
	active,
	month,
	selectedDate,
	onClick,
	children,
}) {
	const day = date.getDate();
	const isMonth = date.getMonth() === month;
	const isSelected = isDateEqual(selectedDate, date);

	const classes = [
		"calendar__cell",
		!isMonth && "is-dimmed",
		isSelected && "is-selected",
	]
		.filter(Boolean)
		.join(" ");

	return active ? (
		<button
			type="button"
			onClick={onClick}
			className={classes}
			disabled={!isMonth}
		>
			<span>{day}</span>
			<>{children}</>
		</button>
	) : (
		<span className={classes}>
			<span>{day}</span>
			<>{children}</>
		</span>
	);
}
