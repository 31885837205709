import { useState } from "react";
import useAPI from "../../hooks/useAPI";
import { formatDate } from "../../utils";
import FormCreate from "../FormTemplate/FormCreate";
import { Button } from "../Template/Button";
import { Star } from "../Template/Star";
import { goToLogs } from "../../endpointConfig";

const DoseCounter = ({ dailyDosage, index, id, active }) => {
	const [count, setCount] = useState(() => parseInt(dailyDosage, 10));
	const handleStepUp = () => setCount(count + 1);
	const handleStepDown = () => setCount(Math.max(count - 1, 0));

	return (
		<div className="cabinet-dose-counter">
			<button
				className="cabinet-dose__counter"
				type="button"
				onClick={handleStepDown}
				disabled={count <= 0}
			>
				&minus;
			</button>
			<input
				className="cabient-dose__input"
				type="number"
				id={`daily-${id}`}
				name={`meds[${index}][daily_dosage]`}
				value={count}
				disabled={!active}
			/>
			<button
				className="cabinet-dose__counter"
				type="button"
				onClick={handleStepUp}
			>
				+
			</button>
		</div>
	);
};

const FieldGroup = ({ id, name, date, dailyDosage, dosage, index, fav }) => {
	const [active, setActive] = useState(false);
	const toggle = () => setActive(!active);

	return (
		<>
			{dailyDosage ? (
				<>
					<label
						className="cabient-meds__title"
						htmlFor={`daily-${id}`}
					>
						{name}
					</label>
					{fav === "1" ? <Star /> : ""}
					<div
						className={`cabinet-dose ${!active ? "disabled" : ""}`}
					>
						<h3 className="cabient-dose__subtitle">Ändra dosen</h3>
						<DoseCounter
							id={id}
							index={index}
							dailyDosage={dailyDosage}
							active={active}
						/>
					</div>
				</>
			) : (
				<>
					<div
						className={`cabinet-placeholder ${
							!active ? "disabled" : ""
						}`}
					></div>
					<h2 className="cabient-meds__title">{name}</h2>
					{fav === "1" ? <Star /> : ""}
				</>
			)}

			<div className={`cabinet-date ${!active ? "disabled" : ""}`}>
				<label htmlFor="daily-date" className="cabinet-date__subtitle">
					Datum
				</label>
				<input
					className="cabinet-date__input"
					type="date"
					id="daily-date"
					name={`meds[${index}][date]`}
					defaultValue={date}
					disabled={!active}
				/>
			</div>

			<div className="cabinet-checkbox">
				<input
					type="hidden"
					name={`meds[${index}][id]`}
					value={id}
					disabled={!active}
				/>
				<input
					className="cabinet-checkbox__input"
					type="checkbox"
					id={`daily-checked-${index}`}
					value={active}
					onChange={toggle}
				/>

				<label
					className="daily-checked toggle"
					htmlFor={`daily-checked-${index}`}
				>
					<div className="cabinet-checkbox__label toggle-indicator"></div>
					<span className="cabinet-checkbox__text" aria-label="Vald">
						{active ? `Tillagd` : `Välj medicin`}
					</span>
				</label>
			</div>
		</>
	);
};

function dailyMedsToJSON(form) {
	const result = [];
	const formData = new FormData(form);

	for (const [name, value] of formData.entries()) {
		const parts = name.split("][");
		const index = Number(parts[0].slice(5));
		const key = parts[1].slice(0, -1);

		if (!result[index]) {
			result[index] = {};
		}

		result[index][key] = value;
	}

	return result.filter((item) => item != null);
}

const DailyMedsFields = ({
	med = {
		date: formatDate(new Date()),
	},
	title = "Dosett",
}) => {
	const { value: dailyMeds } = useAPI("daily-meds", {
		initialValue: [
			{
				id: 0,
				name: "Title",
				dailyDosage: 0,
				dosage: "Lorem ipsum dolor",
			},
			{
				id: 1,
				name: "Title",
				dailyDosage: 0,
				dosage: "Lorem ipsum dolor",
			},
			{
				id: 2,
				name: "Title",
				dailyDosage: 0,
				dosage: "Lorem ipsum dolor",
			},
			{
				id: 3,
				name: "Title",
				dailyDosage: 0,
				dosage: "Lorem ipsum dolor",
			},
			{
				id: 4,
				name: "Title",
				dailyDosage: 0,
				dosage: "Lorem ipsum dolor",
			},
			{
				id: 5,
				name: "Title",
				dailyDosage: 0,
				dosage: "Lorem ipsum dolor",
			},
			{
				id: 6,
				name: "Title",
				dailyDosage: 0,
				dosage: "Lorem ipsum dolor",
			},
		],
	});

	return (
		<fieldset className="cabinet">
			<legend>{title}</legend>
			<ul className="cabinet__list">
				{dailyMeds.map(
					(
						{ id, name, daily_dosage: dailyDosage, dosage, fav },
						i
					) => (
						<li
							key={id}
							className={
								dailyDosage
									? "cabinet__item"
									: "cabinet__item cabinet__item--placeholder"
							}
						>
							<FieldGroup
								index={i}
								id={id}
								name={name}
								date={med.date}
								dosage={dosage}
								dailyDosage={dailyDosage}
								fav={fav}
							/>
						</li>
					)
				)}
			</ul>
			<Button label="Spara" modifier="default" />
		</fieldset>
	);
};

export const AddDailyMeds = () => {
	return (
		<FormCreate
			endpoint="daily-meds"
			goTo={goToLogs}
			formToJSON={dailyMedsToJSON}
			modifier="dailymeds"
		>
			<DailyMedsFields />
		</FormCreate>
	);
};
