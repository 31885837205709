// Based on https://usehooks.com/useAuth/
import { createContext, useContext, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getToken, setToken } from "../data/token";
import api from "../data/api";

const AuthContext = createContext();

export const useAuth = () => {
	return useContext(AuthContext);
};

export function RedirectToLogin() {
	const location = useLocation();

	return (
		<Navigate to="/login" state={{ referrer: location.pathname }} replace />
	);
}

export function RequireAuth({
	children,
	fallback: FallbackComponent = RedirectToLogin,
}) {
	const auth = useAuth();

	if (auth.isAuthenticated) {
		return children;
	}

	return FallbackComponent ? <FallbackComponent /> : null;
}

export function AuthProvider({ children }) {
	const [isAuthenticated, setIsAuthenticated] = useState(
		() => getToken() != null
	);

	const signin = (name, password) =>
		api.post("user/login", { name, password }).then((res) => {
			setToken(res.token);
			setIsAuthenticated(true);
			return res;
		});

	const signout = () =>
		api.get("user/logout").then((res) => {
			setToken(null);
			setIsAuthenticated(false);
			return res;
		});

	const value = {
		isAuthenticated,
		signin,
		signout,
	};

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
}
