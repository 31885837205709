export function getItem(key, defaultValue) {
	const item = sessionStorage.getItem(key);

	if (item == null) {
		return defaultValue;
	}

	try {
		return JSON.parse(item);
	} catch {
		return item;
	}
}
export function removeItem(key) {
	sessionStorage.removeItem(key);
}

export function setItem(key, value) {
	const item = typeof value != "string" ? JSON.stringify(value) : value;

	sessionStorage.setItem(key, item);
}
