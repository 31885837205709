import { useState } from "react";
import { chunk, getUniqueMonths, getUniqueYears } from "../../utils";

function getCalendarDates(date, firstDayOfWeek = 1) {
	const year = date.getFullYear();
	const month = date.getMonth();
	const startdate = new Date(year, month, 1);
	const firstDayOfMonth = startdate.getDay();
	// prettier-ignore
	const diff = (firstDayOfMonth < firstDayOfWeek ? 7 : 0) + firstDayOfMonth - firstDayOfWeek;
	startdate.setDate(startdate.getDate() - diff);
	const daysInMonth = new Date(year, month + 1, 0).getDate();
	const numberOfDays = Math.ceil((daysInMonth + diff) / 7) * 7;
	const start = startdate.getDate();

	return Array.from({ length: numberOfDays }).map((_, i) => {
		const d = new Date(startdate);
		d.setDate(start + i);

		return d;
	});
}

export default function useCalendar(dates) {
	const [date, setDate] = useState(() => {
		const d = new Date();
		d.setDate(1);

		return d;
	});

	function setMonth(month) {
		setDate((state) => {
			return new Date(state.getFullYear(), month, state.getDate());
		});
	}

	function setYear(year) {
		setDate((state) => {
			return new Date(year, state.getMonth(), state.getDate());
		});
	}

	const month = date.getMonth();
	const year = date.getFullYear();
	const calendarDates = chunk(getCalendarDates(date), 7);
	const years = getUniqueYears(dates);
	const months = getUniqueMonths(dates, year);

	return {
		date,
		dates: calendarDates,
		month,
		months,
		year,
		years,
		setMonth,
		setYear,
	};
}
