import { DAY_NAMES } from "../../utils";
import CalendarNav from "./CalendarNav";
import useCalendar from "./useCalendar";

export default function Calendar({
	dates = [],
	renderDate = (date) => date.getDate(),
}) {
	const calendar = useCalendar(dates);
	const month = calendar.date.getMonth();
	const year = calendar.date.getYear();

	return (
		<section className="calendar">
			<table className="calendar__table">
				<thead>
					<tr>
						{DAY_NAMES.map((day) => (
							<th key={day} className="calendar__day">
								{day}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{calendar.dates.map((row, i) => (
						<tr key={i} className="calendar__row">
							{row.map((date) => (
								<td key={date.getDate()}>
									{renderDate(date, { month, year })}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			<footer className="calendar__nav">
				<CalendarNav calendar={calendar} />
			</footer>
		</section>
	);
}
