export const goHome = "/";
export const goOut = "/logout";

export const goToLogs = "/log";
export const goToSurveys = "/surveys";

export const PATH_POS = "positivismum";
export const PATH_MOOD = "motus";
export const PATH_CYCLES = "cycles";
export const PATH_HURT = "tristis";
export const PATH_V = "v";
export const PATH_WEIGHT = "gravitas";
export const PATH_MEDS = "medicines";
export const PATH_SICKNESS = "morbus";
export const PATH_DRUG = "medicamento";
export const PATH_SURVEY = "survey";
