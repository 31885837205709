import { useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
import {
	PATH_MOOD,
	PATH_MEDS,
	PATH_HURT,
	PATH_SICKNESS,
	PATH_POS,
	PATH_CYCLES,
	PATH_DRUG,
	PATH_V,
	goToLogs,
	goToSurveys,
	PATH_SURVEY,
	PATH_WEIGHT,
} from "./endpointConfig";

import Home from "./components/Startpage";
import Menu from "./components/Menu";

import Login from "./components/Login";
import Logout from "./components/Logout";
import { AuthProvider, RequireAuth } from "./components/Auth";

import CalendarTabs from "./components/Logs/CalenderTabs";
import MedicineCalendar from "./components/Logs/MedicineCalendar";
import CyclesCalendar from "./components/Logs/CyclesCalendar";
import EventsCalendar from "./components/Logs/EventsCalendar";
import MoodCalendar from "./components/Logs/MoodCalendar";
import CopulabisTable from "./components/Logs/CopulabisTable";
import HurtnessTable from "./components/Logs/HurtnessTable";
import Sickness from "./components/Logs/SicknessList";
import DrugList from "./components/Logs/DrugList";
import WeightCalendar from "./components/Logs/WeightCalendar";

import { AddSickness, EditSickness } from "./components/Form/SicknessForm";
import { AddCycle, EditCycle } from "./components/Form/CycleForm";
import { AddDrug, EditDrug } from "./components/Form/DrugForm";
import { AddEvent, EditEvent } from "./components/Form/PositiveForm";
import { AddDailyMeds } from "./components/Form/DailyMeds";
import { AddMood } from "./components/Form/MoodForm";
import { AddHurt, EditHurt } from "./components/Form/HurtForm";
import { AddSex, EditSex } from "./components/Form/SexForm";
import { AddWeight, EditWeight } from "./components/Form/WeightForm";

import { AddGAD7 } from "./components/Form/GAD7";
import { AddPCL5 } from "./components/Form/PCL5";
import { AddPHQ9 } from "./components/Form/PHQ9";
import SurveyDashboard from "./components/Survey";

const App = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scroll(0, 0);
	}, [pathname]);

	return (
		<RequireAuth>
			<main className="site__content">
				<Routes>
					<Route
						path={`/${PATH_SURVEY}/gad7`}
						element={<AddGAD7 />}
					/>
					<Route
						path={`/${PATH_SURVEY}/pcl5`}
						element={<AddPCL5 />}
					/>
					<Route
						path={`/${PATH_SURVEY}/phq9`}
						element={<AddPHQ9 />}
					/>
					<Route
						path={`${goToSurveys}`}
						element={<SurveyDashboard />}
					/>
					<Route path={`${goToLogs}/*`} element={<CalendarTabs />} />

					<Route path={`/${PATH_MEDS}`}>
						<Route path="add" element={<AddDailyMeds />} />
						<Route index element={<MedicineCalendar />} />
					</Route>

					<Route path={`/${PATH_MOOD}`}>
						<Route path="add" element={<AddMood />} />
						<Route index element={<MoodCalendar />} />
					</Route>

					<Route path={`/${PATH_WEIGHT}`}>
						<Route path="add" element={<AddWeight />} />
						<Route path=":id/edit" element={<EditWeight />} />
						<Route index element={<WeightCalendar />} />
					</Route>

					<Route path={`/${PATH_HURT}`}>
						<Route path="add" element={<AddHurt />} />
						<Route path=":id/edit" element={<EditHurt />} />
						<Route index element={<HurtnessTable />} />
					</Route>

					<Route path={`/${PATH_V}`}>
						<Route path="add" element={<AddSex />} />
						<Route path=":id/edit" element={<EditSex />} />
						<Route index element={<CopulabisTable />} />
					</Route>

					<Route path={`/${PATH_SICKNESS}`}>
						<Route path="add" element={<AddSickness />} />
						<Route path=":id/edit" element={<EditSickness />} />
						<Route index element={<Sickness />} />
					</Route>

					<Route path={`/${PATH_POS}`}>
						<Route path="add" element={<AddEvent />} />
						<Route path=":id/edit" element={<EditEvent />} />
						<Route index element={<EventsCalendar />} />
					</Route>

					<Route path={`/${PATH_CYCLES}`}>
						<Route path="add" element={<AddCycle />} />
						<Route path=":id/edit" element={<EditCycle />} />
						<Route index element={<CyclesCalendar />} />
					</Route>

					<Route path={`/${PATH_DRUG}`}>
						<Route path="add" element={<AddDrug />} />
						<Route path=":id/edit" element={<EditDrug />} />
						<Route index element={<DrugList />} />
					</Route>

					<Route path="/" index element={<Home />} />
				</Routes>
			</main>
			<footer className="site__footer">
				<Menu />
			</footer>
		</RequireAuth>
	);
};

function AppShell() {
	return (
		<AuthProvider>
			<Router>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/logout" element={<Logout />} />
					<Route path="*" element={<App />} />
				</Routes>
			</Router>
		</AuthProvider>
	);
}

export default AppShell;
