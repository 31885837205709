import { forwardRef } from "react";
import { Link, useLocation, useResolvedPath } from "react-router-dom";

const TabItem = forwardRef(({ to, label }, ref) => {
	const currentPath = useLocation().pathname.toLowerCase();
	const toPath = useResolvedPath(to).pathname.toLowerCase();
	const isSelected = currentPath === toPath;

	return (
		<li>
			<Link
				ref={ref}
				to={to}
				className={isSelected ? "tab is-selected" : "tab"}
			>
				{label}
			</Link>
		</li>
	);
});

export default TabItem;
