import { formatDate, swedishDateFormat } from "../../utils";
import Mood from "./Bubble/Mood";
import Meds from "./Bubble/Meds";
import Events from "./Bubble/Events";
import Weight from "./Bubble/Weight";

const Home = () => (
	<>
		<h1 className="section__title">Dashboard</h1>
		<section className="startpage startpage--summary">
			<h2 className="startpage__title">
				<time dateTime={formatDate(new Date())}>
					{swedishDateFormat(new Date())}
				</time>
			</h2>
			<Meds />
			<Mood />
			<Weight />
			<Events />
		</section>
	</>
);

export default Home;
