import DotLoader from "../DotLoader";

const StartpageSummaryCard = ({ title, children, loading }) => (
	<section className="startpage__bubble">
		<h3 className="startpage__bubble-title">{title}</h3>

		<div className="startpage__inner">
			{loading ? <DotLoader /> : children}
		</div>
	</section>
);

export default StartpageSummaryCard;
