import { Routes, Route } from "react-router-dom";
import {
	PATH_POS,
	PATH_MOOD,
	PATH_CYCLES,
	PATH_HURT,
	PATH_V,
	PATH_SICKNESS,
	PATH_DRUG,
	PATH_WEIGHT,
} from "../../endpointConfig";
import Tabs from "../Tabs";
import Cycles from "./CyclesCalendar";
import Positivism from "./EventsCalendar";
import Meds from "./MedicineCalendar";
import Mood from "./MoodCalendar";
import Injuries from "./HurtnessTable";
import Sex from "./CopulabisTable";
import Sickness from "./SicknessList";
import DrugList from "./DrugList";
import Weight from "./WeightCalendar";

const tabItems = [
	{
		label: "Medicinintag",
		url: "",
	},
	{
		label: "Positivism",
		url: PATH_POS,
	},
	{
		label: "Humörbaromenter",
		url: PATH_MOOD,
	},
	{
		label: "Vikt",
		url: PATH_WEIGHT,
	},
	{
		label: "Menstruation",
		url: PATH_CYCLES,
	},
	{
		label: "Sjukdomar",
		url: PATH_SICKNESS,
	},
	{
		label: "Mediciner",
		url: PATH_DRUG,
	},
	{
		label: "Sex",
		url: PATH_V,
	},
	{
		label: "Skadehistorik",
		url: PATH_HURT,
	},
];

const RenderdCals = () => (
	<>
		<Tabs items={tabItems} />

		<Routes>
			<Route index element={<Meds />} />
			<Route path={PATH_POS} element={<Positivism />} />
			<Route path={PATH_MOOD} element={<Mood />} />
			<Route path={PATH_CYCLES} element={<Cycles />} />
			<Route path={PATH_WEIGHT} element={<Weight />} />
			<Route path={PATH_V} element={<Sex />} />
			<Route path={PATH_HURT} element={<Injuries />} />
			<Route path={PATH_SICKNESS} element={<Sickness />} />
			<Route path={PATH_DRUG} element={<DrugList />} />
		</Routes>
	</>
);

export default RenderdCals;
