import { useParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import Field from "../FormTemplate/Field";
import FormCreate from "../FormTemplate/FormCreate";
import FormUpdate from "../FormTemplate/FormUpdate";
import { Button, AbortButton } from "../Template/Button";
import { FormLoader } from "../Loader";
import { goToLogs, PATH_WEIGHT } from "../../endpointConfig";
import { formatDate } from "../../utils";

const WeightFields = ({
	weight = {
		date: formatDate(new Date()),
	},
	title = "Lägg till: vikt",
}) => (
	<fieldset className="form">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				type="date"
				id="weight-date"
				name="date"
				label="Datum"
				value={weight.date}
				required
			/>
			<Field
				type="number"
				id="weight-amount"
				name="amount"
				label="KG"
				value={weight.amount}
				placeholder="T.ex. 57,5"
				step="0.1"
				required
			/>
			<Field
				as="textarea"
				id="weight-note"
				name="note"
				label="Valfri anteckning"
				value={weight.note}
				rows="8"
			/>
		</div>
		<div className="btn-container">
			<AbortButton
				pathTo={`.${goToLogs}/${PATH_WEIGHT}`}
				label="Avbryt"
			/>
			<Button label="Klar" modifier="default" />
		</div>
	</fieldset>
);

export const AddWeight = () => {
	return (
		<FormCreate endpoint={PATH_WEIGHT} goTo={`${goToLogs}/${PATH_WEIGHT}`}>
			<WeightFields />
		</FormCreate>
	);
};

export const EditWeight = () => {
	const { id } = useParams();
	const endpoint = `${PATH_WEIGHT}/${id}`;
	const { value: weight, loading } = useAPI(endpoint);

	if (loading) {
		return <FormLoader />;
	}

	return (
		<FormUpdate
			endpoint={endpoint}
			goTo={`${goToLogs}/${PATH_WEIGHT}`}
			values={weight}
		>
			<WeightFields title="Redigera vikten" weight={weight} />
		</FormUpdate>
	);
};
