export const Star = () => (
	<svg
		className="fav"
		width="22"
		height="20"
		viewBox="0 0 22 20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M9.09789 1.8541C9.69659 0.0114828 12.3034 0.0114809 12.9021 1.8541L13.9187 4.98278C14.1864 5.80682 14.9543 6.36475 15.8208 6.36475H19.1105C21.0479 6.36475 21.8535 8.84398 20.2861 9.98278L17.6246 11.9164C16.9237 12.4257 16.6303 13.3284 16.8981 14.1525L17.9147 17.2812C18.5134 19.1238 16.4044 20.656 14.837 19.5172L12.1756 17.5836C11.4746 17.0743 10.5254 17.0743 9.82443 17.5836L7.16302 19.5172C5.59559 20.656 3.48663 19.1238 4.08533 17.2812L5.1019 14.1525C5.36965 13.3284 5.07634 12.4257 4.37536 11.9164L1.71395 9.98278C0.146523 8.84398 0.952073 6.36475 2.88952 6.36475H6.1792C7.04566 6.36475 7.81357 5.80682 8.08132 4.98278L9.09789 1.8541Z" />
	</svg>
);
