import useAPI from "../../../hooks/useAPI";
import StartpageBubble from "../StartpageBubble";
import DisplayNoDataMessage from "../../Template/DisplayNoDataMessage";

const Weight = () => {
	const { value: weight, loading } = useAPI("gravitas/today");

	return (
		<StartpageBubble title="Vikt" loading={loading}>
			{weight.id != null ? (
				<div>{weight.amount} kg</div>
			) : (
				<DisplayNoDataMessage classes="startpage__bubble-msg" />
			)}
		</StartpageBubble>
	);
};

export default Weight;
