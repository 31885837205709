import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import api from "../../data/api";

function defaultDataMapper(form) {
	const formData = new FormData(form);
	const data = Object.fromEntries(formData.entries());

	return data;
}

const FormCreate = ({
	endpoint,
	goTo,
	children,
	formToJSON = defaultDataMapper,
	modifier,
}) => {
	const navigate = useNavigate();

	const { trigger } = useSWRMutation(
		endpoint,
		(url, { arg: body }) => {
			return api.post(url, body);
		},
		{
			onSuccess: () => {
				navigate(goTo);
			},
		}
	);

	const onFormSubmit = async (e) => {
		e.preventDefault();

		const data = formToJSON(e.currentTarget);
		trigger(data);
	};

	return (
		<form
			className={`${
				modifier
					? `form-wrapper form-wrapper--${modifier}`
					: "form-wrapper"
			}`}
			onSubmit={onFormSubmit}
			action={endpoint}
			method="post"
		>
			{children}
		</form>
	);
};

export default FormCreate;
