import useAPI from "../../hooks/useAPI";
import useGroupRelativeTime from "../../hooks/useGroupRelativeTime";
import { shortDateFormat, groupByYear } from "../../utils";
import { EditButtonXS } from "../Template/Button";
import Loader from "../Loader";
import { PATH_V } from "../../endpointConfig";
import Accordion from "../Accordion";

const CopulabisTable = () => {
	const { value: groupedYears, loading } = useAPI(PATH_V, {
		handler: groupByYear,
		initialValue: new Map(),
	});

	const relativeTime = useGroupRelativeTime(groupedYears);

	return (
		<section className="section section--table">
			<h1 className="section__title">Sex</h1>
			<p className="section__stats">Det är {relativeTime} sen.</p>
			{loading ? (
				<Loader />
			) : (
				<>
					{Array.from(groupedYears, ([year, items]) => (
						<Accordion key={year} name={year} modifier="table">
							<table>
								<thead>
									<tr>
										<th>Datum</th>
										<th>Antal</th>
										<th>Redigera</th>
									</tr>
								</thead>
								<tbody>
									{items.map(({ id, date, total }) => (
										<tr key={id}>
											<td>{shortDateFormat(date)}</td>
											<td className="table-cell">
												{total ?? ""}
											</td>
											<td className="table-cell">
												<EditButtonXS
													id={id}
													segment={PATH_V}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</Accordion>
					))}
				</>
			)}
		</section>
	);
};

export default CopulabisTable;
