const Loader = ({ modifier }) => (
	<div className={`loader ${modifier ? `loader--${modifier}` : ""}`}>
		<div className="heart"></div>
	</div>
);

export const CalendarLoader = () => <Loader modifier="calendar" />;

export const FormLoader = () => <Loader modifier="editform" />;

export default Loader;
