import { useMemo, useState } from "react";
import useAPI from "../../hooks/useAPI";
import Score from "../Template/GraphScore";
import { getUniqueYears } from "../../utils";

const surveyConfig = {
	phq9: {
		levels: [27, 15, 9, 0],
		title: "PHQ-9",
		subtitle: "Patient Health Questionnaire",
	},
	gad7: {
		levels: [22, 15, 10, 5, 0],
		title: "GAD-7",
		subtitle: "Generalised Anxiety Disorder 7-item scale",
	},
	pcl5: {
		levels: [80, 38, 0],
		title: "PCL-5",
		subtitle: "Posttraumatic Stress Disorder Checklist (version 5)",
	},
};

const SurveyDashboard = () => {
	const [survey, setSurvey] = useState("phq9");
	const [year, setYear] = useState("");
	const api = `survey/${survey}`;
	const surveys = Object.keys(surveyConfig);
	const surveyProps = surveyConfig[survey];

	const { value: data } = useAPI(api, {
		initialValue: [
			{ id: 0, total: 0, date: "1970-01-01" },
			{ id: 1, total: 0, date: "1970-01-01" },
			{ id: 2, total: 0, date: "1970-01-01" },
			{ id: 3, total: 0, date: "1970-01-01" },
			{ id: 4, total: 0, date: "1970-01-01" },
			{ id: 5, total: 0, date: "1970-01-01" },
			{ id: 6, total: 0, date: "1970-01-01" },
			{ id: 7, total: 0, date: "1970-01-01" },
			{ id: 8, total: 0, date: "1970-01-01" },
			{ id: 9, total: 0, date: "1970-01-01" },
			{ id: 10, total: 0, date: "1970-01-01" },
			{ id: 11, total: 0, date: "1970-01-01" },
			{ id: 12, total: 0, date: "1970-01-01" },
			{ id: 13, total: 0, date: "1970-01-01" },
			{ id: 14, total: 0, date: "1970-01-01" },
			{ id: 15, total: 0, date: "1970-01-01" },
			{ id: 16, total: 0, date: "1970-01-01" },
		],
	});

	const filteredData = useMemo(() => {
		return year ? data.filter((x) => x.date.substr(0, 4) === year) : data;
	}, [year, data]);

	const years = useMemo(() => {
		const dates = data.map((x) => x.date);
		return getUniqueYears(dates);
	}, [data]);

	const onYearChange = (e) => {
		const value = e.currentTarget.value;
		setYear(value);
	};

	const onSurveyChange = (e) => {
		const value = e.currentTarget.value;
		setSurvey(value);
	};

	return (
		<section className="graph-container">
			<h1>{surveyProps.title}</h1>
			<p>{surveyProps.subtitle}</p>

			<div className="graph-container__filter">
				<select
					value={survey}
					onChange={onSurveyChange}
					className="graph-container__select"
				>
					{surveys.map((value) => (
						<option key={value} value={value}>
							{surveyConfig[value].title}
						</option>
					))}
				</select>

				<select
					value={year}
					onChange={onYearChange}
					className="graph-container__select"
				>
					<option value="">År</option>
					{years.map((value) => (
						<option key={value} value={value}>
							{value}
						</option>
					))}
				</select>
			</div>

			<Score levels={surveyProps.levels} data={filteredData} />
		</section>
	);
};

export default SurveyDashboard;
