import { useState } from "react";
import { useParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import { formatDate } from "../../utils";
import Field from "../FormTemplate/Field";
import FormCreate from "../FormTemplate/FormCreate";
import FormUpdate from "../FormTemplate/FormUpdate";
import { Button, AbortButton } from "../Template/Button";
import { FormLoader } from "../Loader";
import { goToLogs, PATH_POS } from "../../endpointConfig";

const Radio = ({ id, label, modifier, onChange, checked, ...rest }) => {
	return (
		<div
			className={`field-radio ${modifier ? `field-radio--${modifier}` : ""
				}`}
		>
			<input
				id={`positivism-${id}`}
				name="event"
				type="radio"
				className="field-radio__input"
				onChange={onChange}
				checked={checked}
				{...rest}
			/>
			<label htmlFor={`positivism-${id}`} className="field-radio__label">
				{label}
			</label>
		</div>
	);
};

const EventFields = ({
	event = {
		date: formatDate(new Date()),
	},
	title = "Lägg till dagens positiva händelser",
}) => {
	const [state, setState] = useState(
		event.event === undefined ? null : !!event.event
	);

	return (
		<fieldset className="form">
			<legend>{title}</legend>
			<div className="form__layout positive-form">
				<Field
					type="date"
					id="positivism-date"
					name="date"
					label="Datum"
					value={event.date}
				/>
				<Radio
					id="great"
					label="Bra dag"
					onChange={() => setState(true)}
					checked={state === true}
					modifier="good"
				/>
				{state && (
					<Field
						as="textarea"
						id="positivism-event"
						name="event"
						label="Valfri anteckning"
						value={event.event}
						rows="8"
					/>
				)}
				<Radio
					id="bad"
					label="Dålig dag"
					onChange={() => setState(false)}
					checked={state === false}
					value=""
					modifier="bad"
				/>
			</div>
			<div className="btn-container">
				<AbortButton
					pathTo={`.${goToLogs}/${PATH_POS}`}
					label="Avbryt"
				/>
				<Button label="Klar" modifier="default" />
			</div>
		</fieldset>
	);
};

export const AddEvent = () => {
	return (
		<FormCreate endpoint={PATH_POS} goTo={`${goToLogs}/${PATH_POS}`}>
			<EventFields />
		</FormCreate>
	);
};

export const EditEvent = () => {
	const { id } = useParams();
	const endpoint = `${PATH_POS}/${id}`;
	const { value: event, loading } = useAPI(endpoint);

	if (loading) {
		return <FormLoader />;
	}

	return (
		<FormUpdate
			endpoint={endpoint}
			goTo={`${goToLogs}/${PATH_POS}`}
			values={event}
		>
			<EventFields title="Redigera händelse" event={event} />
		</FormUpdate>
	);
};
