import { MONTH_NAMES } from "../../utils";

function generateYearsRange(years) {
	const currentYear = new Date().getFullYear();
	const yearsToRender = years.includes(currentYear)
		? years
		: [...years, currentYear];
	const min = Math.min(...yearsToRender);
	const max = Math.max(...yearsToRender);
	const length = max - min + 1;

	return Array.from({ length }, (_, i) => {
		return min + i;
	}).reverse();
}

export default function CalendarNav({ calendar }) {
	const { month, months, year, years, setMonth, setYear } = calendar;
	const yearsToRender = generateYearsRange(years);

	return (
		<>
			<select
				value={year}
				onChange={(e) => {
					setYear(e.currentTarget.value);
				}}
				className="calendar__select"
			>
				{yearsToRender.map((value) => (
					<option
						key={value}
						value={value}
						disabled={!years.includes(value)}
					>
						{value}
					</option>
				))}
			</select>

			<select
				value={month}
				onChange={(e) => {
					setMonth(e.currentTarget.value);
				}}
				className="calendar__select"
			>
				{MONTH_NAMES.map((text, value) => (
					<option
						key={text}
						value={value}
						disabled={!months.includes(value)}
					>
						{text}
					</option>
				))}
			</select>
		</>
	);
}
