import { useParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import Field from "../FormTemplate/Field";
import FormCreate from "../FormTemplate/FormCreate";
import FormUpdate from "../FormTemplate/FormUpdate";
import { Button, AbortButton } from "../Template/Button";
import { FormLoader } from "../Loader";
import { goToLogs, PATH_SICKNESS } from "../../endpointConfig";

const SicknessFields = ({ morbus = {}, title = "Lägg till: sjukdom" }) => (
	<fieldset className="form">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				id="sickness-name"
				name="name"
				label="Namn på sjukdom"
				value={morbus.name}
				required
			/>
			<section className="form__cell-grid">
				<h2>Datum</h2>
				<Field
					id="sickness-start"
					name="startdate"
					type="date"
					label="Start"
					value={morbus.startdate}
					required
				/>
				<Field
					id="sickness-end"
					name="enddate"
					type="date"
					label="Slut"
					value={morbus.enddate}
				/>
			</section>
			<Field
				as="textarea"
				id="sickness-symptom"
				name="symptom"
				label="Symptom"
				value={morbus.symptom}
				rows="4"
			/>
			<Field
				as="textarea"
				id="sickness-note"
				name="note"
				label="Valfri anteckning"
				value={morbus.note}
				rows="8"
			/>
		</div>
		<div className="btn-container">
			<AbortButton
				pathTo={`.${goToLogs}/${PATH_SICKNESS}`}
				label="Avbryt"
			/>
			<Button label="Klar" modifier="default" />
		</div>
	</fieldset>
);

export const AddSickness = () => {
	return (
		<FormCreate
			endpoint={PATH_SICKNESS}
			goTo={`${goToLogs}/${PATH_SICKNESS}`}
		>
			<SicknessFields />
		</FormCreate>
	);
};

export const EditSickness = () => {
	const { id } = useParams();
	const endpoint = `${PATH_SICKNESS}/${id}`;
	const { value: morbus, loading } = useAPI(endpoint);

	if (loading) {
		return <FormLoader />;
	}

	return (
		<FormUpdate
			endpoint={endpoint}
			goTo={`${goToLogs}/${PATH_SICKNESS}`}
			values={morbus}
		>
			<SicknessFields title={`Redigera ${morbus.name}`} morbus={morbus} />
		</FormUpdate>
	);
};
