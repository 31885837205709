import { formatDate } from "../../utils";
import Field from "../FormTemplate/Field";
import RadioOption from "../FormTemplate/Radio";
import FormCreate from "../FormTemplate/FormCreate";
import { Button } from "../Template/Button";

const table = "survey/pcl5";

const Answers = ({ number }) => (
	<>
		<RadioOption
			number={number}
			cat="q"
			option="none"
			label="Inte alls"
			value="0"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="slightly"
			label="Lite grann"
			value="1"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="moderate"
			label="Måttligt"
			value="2"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="much"
			label="Ganska mycket"
			value="3"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="extreme"
			label="Extremt"
			value="4"
		/>
	</>
);

const Section = ({ q, num }) => (
	<section className="field field--survey">
		<h3>{q}</h3>
		<Answers number={num} />
	</section>
);

const PCL5Fields = ({
	pcl5 = {
		date: formatDate(new Date()),
	},
	title = "PCL-5",
}) => (
	<fieldset className="form form--survey">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				id="suvey-date"
				name="date"
				type="date"
				label="Datum"
				value={pcl5.date}
			/>
			<section className="form__note">
				<h3>Anteckning</h3>
				<p>Svara för den senaste månaden.</p>
			</section>
			<p className="form__instructions">
				Fyll i enkäten utifrån hur det har varit sedan föregående gång.
				Hur mycket var du besvärad av:
			</p>
			<Section
				q="Upprepade, störande och oönskade minnen av den stressande erfarenheten"
				num="1"
			/>
			<Section
				q="Upprepade, störande drömmar om den stressfyllda erfarenheten?"
				num="2"
			/>
			<Section
				q="Plötsligt känner eller agerar som om den stressfyllda erfarenheten faktiskt händer igen (som om du var faktiskt tillbaka, återupplevde den)?"
				num="3"
			/>
			<Section
				q="Känna sig mycket upprörd när något påminde dig om den stressfyllda erfarenheten?"
				num="4"
			/>
			<Section
				q="Ha starka fysiska reaktioner när något påminde dig om den stressfyllda erfarenheten?"
				num="5"
			/>
			<Section
				q="Undvika minnen, tankar eller känslor relaterade till den stressfyllda erfarenheten?"
				num="6"
			/>
			<Section
				q="Undvika yttre påminnelser om den stressfyllda erfarenheten (till exempel, människor, platser, samtal, aktiviteter, objekt, eller situationer)?"
				num="7"
			/>
			<Section
				q="Problem att komma ihåg viktiga delar av den stressfyllda erfarenheten?"
				num="8"
			/>
			<Section
				q="Ha starka negativa föreställningar om dig själv, andra människor, eller världen (till exempel, ha tankar som: Jag är dålig, det är något allvarligt fel med mig, ingen kan litas på, världen är fullständigt farlig)?"
				num="9"
			/>
			<Section
				q="Beskylla dig själv eller någon annan för den stressfyllda erfarenheten eller det som hände efter den?"
				num="10"
			/>
			<Section
				q="Ha starka negativa känslor som rädsla, fasa, ilska, skuld eller skam?"
				num="11"
			/>
			<Section
				q="Förlust av intresse i aktiviteter som du brukade njuta av?"
				num="12"
			/>
			<Section
				q="Känna sig avlägsen eller avskuren från andra människor?"
				num="13"
			/>
			<Section
				q="Problem att uppleva positiva känslor (till exempel, vara oförmögen att känna lycka eller ha känslor av kärlek för människor nära dig)?"
				num="14"
			/>
			<Section
				q="Irriterat beteende, ilskeutbrott eller agera aggressivt?"
				num="15"
			/>
			<Section
				q="Ta för många risker eller göra saker som kan orsaka dig skada?"
				num="16"
			/>
			<Section q="Vara superalert eller vaksam eller på vakt?" num="17" />
			<Section q="Känna sig nervös eller lättskrämd?" num="18" />
			<Section q="Ha svårt att koncentrera sig?" num="19" />
			<Section q="Problem att somna eller att sova ihållande?" num="20" />
		</div>
		<div className="btn-container">
			<Button label="Spara" modifier="default" />
		</div>
	</fieldset>
);

export const AddPCL5 = () => {
	return (
		<FormCreate endpoint={table} goTo="/surveys">
			<PCL5Fields />
		</FormCreate>
	);
};
