import { useParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import FormCreate from "../FormTemplate/FormCreate";
import FormUpdate from "../FormTemplate/FormUpdate";
import Field from "../FormTemplate/Field";
import { Button, AbortButton } from "../Template/Button";
import { FormLoader } from "../Loader";
import { swedishDateFormat, formatDate } from "../../utils";
import { goToLogs, PATH_V } from "../../endpointConfig";

const SexFields = ({
	v = {
		date: formatDate(new Date()),
	},
	title = "Tillfälle",
}) => (
	<fieldset className="form">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				type="date"
				id="v-date"
				name="date"
				label="Datum"
				value={v.date}
				required
			/>
			<Field
				type="number"
				id="v-total"
				name="total"
				label="Totalt"
				value={v.total}
				placeholder="Ange en siffra.."
				required
			/>
		</div>
		<div className="btn-container">
			<AbortButton pathTo={`.${goToLogs}/${PATH_V}`} label="Avbryt" />
			<Button label="Klar" modifier="default" />
		</div>
	</fieldset>
);

export const AddSex = () => {
	return (
		<FormCreate endpoint={PATH_V} goTo={`${goToLogs}/${PATH_V}`}>
			<SexFields />
		</FormCreate>
	);
};

export const EditSex = () => {
	const { id } = useParams();
	const endpoint = `${PATH_V}/${id}`;
	const { value: copulabis, loading } = useAPI(endpoint);

	if (loading) {
		return <FormLoader />;
	}

	return (
		<FormUpdate
			endpoint={endpoint}
			goTo={`${goToLogs}/${PATH_V}`}
			values={copulabis}
		>
			<SexFields
				title={`Redigera ${swedishDateFormat(copulabis.date)}`}
				v={copulabis}
			/>
		</FormUpdate>
	);
};
