import { useNavigate } from "react-router-dom";
import api from "../../data/api";
import { DeleteButton } from "../Template/Button";

const Delete = ({ id, label, type }) => {
	const navigate = useNavigate();

	const onClick = (e) => {
		e.preventDefault();

		if (window.confirm(`Är du säker att du vill radera "${label}"?`)) {
			api.delete(`${type}/${id}`).then(() => {
				navigate("/");
				alert("success");
			});
		}
	};

	return <DeleteButton label="Radera" modifier="delete" onClick={onClick} />;
};

export default Delete;
