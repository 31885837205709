const RadioOption = ({ number, cat, option, label, value }) => (
	<div className="field-radio">
		<input
			id={`${cat}${number}-${option}`}
			name={`${cat}${number}`}
			type="radio"
			value={value}
			className="field-radio__input"
		/>
		<label
			htmlFor={`${cat}${number}-${option}`}
			className="field-radio__label"
		>
			{label}
		</label>
	</div>
);

export default RadioOption;
