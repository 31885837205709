import { useState, useMemo, Fragment, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
	timeBetween,
	beginningOfDay,
	endOfDay,
	shortDateFormat,
	isDateEqual,
	swedishDateFormat,
} from "../../utils";
import useAPI from "../../hooks/useAPI";
import useOutsideClick from "../../hooks/useOutsideClick";
import Calendar from "../Calendar";
import CalendarCell from "../Calendar/CalendarCell";
import CalendarHeader from "../Calendar/CalendarHeader";
import DisplayNoDataMessage from "../Template/DisplayNoDataMessage";
import Delete from "../FormTemplate/Delete";
import { EditButton } from "../Template/Button";
import { CalendarLoader } from "../Loader";
import DataBubble from "./DataBubble";

function flowScale(value) {
	if (value === "light") return "Lätt";
	if (value === "normal") return "Normal";
	if (value === "super") return "Rikligt";
}

function painScale(value) {
	if (value === "none") return "Ingen";
	if (value === "mild") return "Lätt";
	if (value === "moderate") return "Måttlig";
	if (value === "severe") return "Svår";
	if (value === "unbearable") return "Outhärdlig";
	if (value === "worst") return "Värsta tänkbara";
}

const dateRangeMatch = (date) => (x) => {
	const start = beginningOfDay(x.startdate);
	const end = endOfDay(x.enddate);

	return start <= date && end >= date;
};

const CyclesCalendar = () => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [openBubble, setOpenBubble] = useState(false);
	const { value: cycles, loading } = useAPI("cycles");
	const bubbleRef = useRef(null);
	const { pathname } = useLocation();
	// prettier-ignore
	const classes = `calendar-presentation ${openBubble ? "is-open" : ""}`.trim();

	useEffect(() => {
		setOpenBubble(false);
	}, [pathname]);

	useOutsideClick(bubbleRef, () => {
		if (openBubble) setOpenBubble(false);
	});

	const dates = useMemo(() => {
		const msInADay = 1000 * 60 * 60 * 24;

		return cycles.flatMap((item) => {
			const start = beginningOfDay(item.startdate);
			const end = endOfDay(item.enddate);
			const rangeDiff = Math.round((end - start) / msInADay);

			const allDaysInRange = Array.from(
				{ length: rangeDiff },
				(_, index) => {
					const date = new Date(start);
					date.setDate(date.getDate() + index);

					return date;
				}
			);

			return allDaysInRange;
		});
	}, [cycles]);

	const selectedLoggItems = useMemo(() => {
		const isInDateRange = dateRangeMatch(selectedDate);

		return cycles.filter(isInDateRange);
	}, [selectedDate, cycles]);

	const renderDate = (date, { month }) => {
		const active = dates.some((x) => isDateEqual(x, date));

		return (
			<CalendarCell
				date={date}
				month={month}
				selectedDate={selectedDate}
				onClick={() => {
					setSelectedDate(date);
					requestAnimationFrame(() => {
						setOpenBubble(true);
					});
				}}
				active={active}
			>
				{active ? <div className="calendar__cell-dot"></div> : null}
			</CalendarCell>
		);
	};

	return (
		<section className="section section--calendar">
			<h1 className="section__title">Cykler</h1>

			{loading ? (
				<CalendarLoader />
			) : (
				<>
					<Calendar dates={dates} renderDate={renderDate} />
					<section className={classes} ref={bubbleRef}>
						{selectedLoggItems.length > 0 ? (
							selectedLoggItems.map(
								({
									id,
									startdate,
									enddate,
									flow,
									pain,
									note,
								}) => (
									<Fragment key={id}>
										<CalendarHeader
											title=""
											cta={() => setOpenBubble(false)}
										>
											<time dateTime={startdate}>
												{shortDateFormat(startdate)}
											</time>
											<span> &ndash; </span>
											{enddate ? (
												<time dateTime={enddate}>
													{shortDateFormat(enddate)}
												</time>
											) : (
												<time
													dateTime={
														new Date(Date.now())
													}
												>
													pågående...
												</time>
											)}
										</CalendarHeader>
										<dl className="calendar-presentation__logg">
											{flow ? (
												<DataBubble
													label="Flöde"
													content={flowScale(flow)}
												/>
											) : (
												""
											)}
											{pain ? (
												<DataBubble
													label="Smärtnivå"
													content={painScale(pain)}
												/>
											) : (
												""
											)}
											{note ? (
												<DataBubble
													label="Anteckning"
													content={note}
												/>
											) : (
												""
											)}
											<DataBubble
												label="Varaktighet"
												content={timeBetween(
													enddate,
													startdate
												)}
											/>
										</dl>
										<div className="btn-container">
											<Delete
												id={id}
												label={
													enddate
														? `Menscykel ${startdate} - ${enddate}`
														: `en pågående cykel`
												}
												type="cycles"
											/>
											<EditButton
												id={id}
												segment="cycles"
											/>
										</div>
									</Fragment>
								)
							)
						) : (
							<>
								<h2 className="calendar-presentation__title">
									{swedishDateFormat(selectedDate)}
								</h2>
								<DisplayNoDataMessage classes="calendar-presentation__msg" />
							</>
						)}
					</section>
				</>
			)}
		</section>
	);
};

export default CyclesCalendar;
