import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, RedirectToLogin } from "./Auth";
import { wait } from "../utils";

export default function Logout() {
	const navigate = useNavigate();
	const { isAuthenticated, signout } = useAuth();
	const wasAuthenticated = useRef(isAuthenticated).current;

	useEffect(() => {
		if (!wasAuthenticated) return;

		signout()
			.then(() => wait())
			.then(() => navigate("/"));
	}, [wasAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!wasAuthenticated) {
		return <RedirectToLogin />;
	}

	return (
		<section className="signout-success">
			<h1>Du är utloggad!</h1>
		</section>
	);
}
