import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { getDiff } from "../../utils";
import api from "../../data/api";

const FormUpdate = ({ endpoint, goTo, children, values }) => {
	const navigate = useNavigate();

	const { trigger } = useSWRMutation(
		endpoint,
		(url, { arg: body }) => {
			return api.put(url, body);
		},
		{
			onSuccess: () => {
				navigate(goTo);
			},
		}
	);

	const onFormSubmit = async (e) => {
		e.preventDefault();

		const formData = new FormData(e.currentTarget);
		const data = Object.fromEntries(formData.entries());
		const changes = getDiff(values, data);

		trigger(changes);
	};

	return (
		<form
			className="form-wrapper"
			onSubmit={onFormSubmit}
			action={endpoint}
			method="post"
		>
			{children}
		</form>
	);
};

export default FormUpdate;
