import { useId, useState } from "react";

const Accordion = ({ name, children, modifier, ...rest }) => {
	const [toggle, setToggle] = useState(true);
	const id = useId();
	const titleId = `title-${id}`;
	const panelId = `panel-${id}`;
	const classes = `accordion ${modifier ? `accordion--${modifier}` : ""}`;

	return (
		<div className={classes} {...rest}>
			<h2 className="accordion__name" id={titleId}>
				<button
					type="button"
					onClick={() => setToggle(!toggle)}
					aria-expanded={!toggle}
					aria-controls={panelId}
				>
					{name}
				</button>
			</h2>
			<section
				className="accordion__content"
				id={panelId}
				aria-hidden={toggle ? "true" : "false"}
				aria-labelledby={titleId}
			>
				<div>{children}</div>
			</section>
		</div>
	);
};

export default Accordion;
