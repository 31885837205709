import { getItem, removeItem, setItem } from "./session";

let token = null;

export const getToken = () => {
	return token || getItem("__token");
};

export const setToken = (value) => {
	token = value;

	if (value != null) {
		setItem("__token", value);
	} else {
		removeItem("__token");
	}
};
