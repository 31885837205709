import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./Auth";
import Field from "./FormTemplate/Field";
import { Button } from "./Template/Button";

export function LoginForm({
	onSuccess = () => console.log("Login success :)"),
	onError = (err) => console.error("Login error :(", err),
}) {
	const auth = useAuth();
	const [hasError, setHasError] = useState(false);

	const onFormSubmit = (e) => {
		e.preventDefault();

		const form = new FormData(e.currentTarget);
		const name = form.get("name");
		const password = form.get("password");

		auth.signin(name, password)
			.then(() => {
				onSuccess();
			})
			.catch((err) => {
				onError();
				console.error(err);
				setHasError(true);
			});
	};

	return (
		<form
			method="post"
			onSubmit={onFormSubmit}
			className="form-wrapper form-wrapper--login"
		>
			<fieldset className="form form--login">
				<legend>Logga in</legend>

				{hasError && (
					<small className="u-error-msg">
						Fel användare eller lösenord
					</small>
				)}

				<div className="form__layout">
					<Field
						id="name"
						name="name"
						type="text"
						label="Användarnamn"
						placeholder="Sergeant Luddstövel"
						autoFocus
					/>
					<Field
						id="password"
						name="password"
						type="password"
						label="Lösenord"
						placeholder="******"
					/>
				</div>

				<Button label="Logga in" modifier="default" />
			</fieldset>
		</form>
	);
}

export default function Login() {
	const navigate = useNavigate();
	const location = useLocation();
	const referrer = location.state?.referrer || "/";

	const onSuccess = () => {
		navigate(referrer);
	};

	return <LoginForm onSuccess={onSuccess} />;
}
