import useAPI from "../../hooks/useAPI";
import useGroupRelativeTime from "../../hooks/useGroupRelativeTime";
import { shortDateFormat, groupByYear } from "../../utils";
import { EditButtonXS } from "../Template/Button";
import Loader from "../Loader";
import Accordion from "../Accordion";

const HurtnessTable = () => {
	const { value: groupedYears, loading } = useAPI("tristis", {
		handler: groupByYear,
		initialValue: new Map(),
	});

	const relativeTime = useGroupRelativeTime(groupedYears);

	return (
		<section className="section section--table">
			<h1 className="section__title">Skador</h1>
			<p className="section__stats">
				Du har varit skadefri i {relativeTime}.
			</p>
			{loading ? (
				<Loader />
			) : (
				<>
					{Array.from(groupedYears, ([year, items]) => (
						<Accordion key={year} name={year} modifier="table">
							<table>
								<thead>
									<tr>
										<th>Datum</th>
										<th>Antal</th>
										<th>Redigera</th>
									</tr>
								</thead>
								<tbody>
									{items.map(({ id, date, total }) => (
										<tr key={id}>
											<td>{shortDateFormat(date)}</td>
											<td>{total ?? ""}</td>
											<td>
												<EditButtonXS
													id={id}
													segment="tristis"
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</Accordion>
					))}
				</>
			)}
		</section>
	);
};

export default HurtnessTable;
