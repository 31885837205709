import { useEffect, useRef } from "react";

function useIsFirstRender() {
	const isFirst = useRef(true);

	if (isFirst.current) {
		isFirst.current = false;
		return true;
	}

	return false;
}

export function useUpdateEffect(effect, deps = []) {
	const isFirst = useIsFirstRender();

	useEffect(() => {
		if (!isFirst) {
			effect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}
