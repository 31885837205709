import { useEffect } from "react";

const useOutsideClick = (ref, handler) => {
	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (ref.current && !ref.current.contains(e.target)) {
				handler(e);
			}
		};

		document.addEventListener("click", checkIfClickedOutside);

		return () =>
			document.removeEventListener("click", checkIfClickedOutside);
	}, [ref, handler]);
};

export default useOutsideClick;
